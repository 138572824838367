import { Button, CheckBox, DataGrid, Popup, SelectBox, TextBox } from 'devextreme-react'
import React, { useContext, useRef, useState } from 'react'
import useWindowSize from '../../hooks/useWindowSizze'
import CustomStore from 'devextreme/data/custom_store';
import { Column, Button as DGButton, Editing, SearchPanel, Toolbar, Item, Scrolling, LoadPanel as DGLoadPanel } from 'devextreme-react/data-grid';
import "./Users.css";
import imgDelete from '../../img/delete.png';
import imgEdit from '../../img/edit.png';
import imgGoBack from '../../img/go_back.png';
import check_rounded_big from '../../img/check_rounded_big.png';
import { Link, useHistory } from 'react-router-dom';
import { Position, ToolbarItem } from 'devextreme-react/popover';
import { Field, Form, Formik } from 'formik';
import { BackButton } from '../common/BackButton';
import { SessionContext } from '../context/SessionContext';
import { InfoPopover } from '../common/InfoPopover';
import imgSpinner from '../../img/spinner.gif';

export const Users = () => {

    let history = useHistory();

    const editFormRef = useRef();
    const createFormRef = useRef();
    const lopdFormRef = useRef();

    const { session: sessionContext, setSession: setSessionContext } = useContext(SessionContext);

    const [createUserState, setCreateUserState] = useState({ loading: false, email: null, name: null, type: null, error: false,  result: null });
    const [editUserState, setEditUserState] = useState({ id: -1, loading: false, email: null, name: null, type: null, error: false,  result: null });
    const [lopdState, setLopdState] = useState({ loading: false, useLopd: false, passwordDuration: 365, passwordRotation: 10, error: false, result: null });
    const [userCountState, setUserCountState] = useState(0);

    const [createDialogState, setCreateDialogState] = useState({
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showCreateDialog = (title, header, body) => {
        setCreateDialogState({
            title: title,
            header: header,
            body: body,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
        if (createFormRef != undefined && createFormRef != null && createFormRef.current != undefined && createFormRef.current != null) {
            createFormRef.current.setErrors({});
        }
    }
    const hideCreateDialog = () => {
        setCreateDialogState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }



    const [lopdDialogState, setLopdDialogState] = useState({
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showLopdDialog = (title, header, body) => {
        setLopdDialogState({
            title: title,
            header: header,
            body: body,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
        if (lopdFormRef != undefined && lopdFormRef != null && lopdFormRef.current != undefined && lopdFormRef.current != null) {
            lopdFormRef.current.setErrors({});
        }
    }
    const hideLopdDialog = () => {
        setLopdDialogState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }



    const [editDialogState, setEditDialogState] = useState({
        id: -1,
        name: "",
        email: "",
        type: 0,
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showEditDialog = (title, id, name, email, type) => {
        setEditDialogState({
            id: id,
            name: name,
            email: email,
            type: type,
            title: title,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
        if (editFormRef != undefined && editFormRef != null && editFormRef.current != undefined && editFormRef.current != null) {
            editFormRef.current.setFieldValue("name", name, false);
            editFormRef.current.setFieldValue("email", email, false);
            editFormRef.current.setFieldValue("type", Number(type), false);
            editFormRef.current.setErrors({});
        }
    }
    const hideEditDialog = () => {
        setEditDialogState({
            id: -1,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }




    const [genericDialogState, setGenericDialogState] = useState({
        title: "",
        body: "",
        okText: "",
        koText: "",
        okCallback: () => {

        },
        koCallback: () => {

        },
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showGenericDialog = (title, body, okText, koText, okCallback, koCallback) => {
        setGenericDialogState({
            title: title,
            body: body,
            okText: okText,
            koText: koText,
            okCallback: okCallback,
            koCallback: koCallback,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }
    const hideGenericDialog = () => {
        setGenericDialogState({
            title: "",
            body: "",
            okText: "",
            koText: "",
            okCallback: () => {

            },
            koCallback: () => {

            },
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }




    const [mailDialogState, setMailDialogState] = useState({
        title: "",
        header: "",
        body: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showMailDialog = (title, target) => {
        setMailDialogState({
            title: title,
            target: target,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }
    const hideMailDialog = () => {
        setMailDialogState({
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }



    const DXTextInput = ({ fieldName, fieldType, fieldPlaceholder, ...rest }) => (
        <Field name={fieldName} id={fieldName} type={fieldType} placeholde={fieldPlaceholder} >
            {({ field, form }) => (
                <TextBox {...rest}
                    name={field.name}
                    placeholder={fieldPlaceholder}
                    mode={fieldType}
                    onChange={event => { form.setFieldValue(field.name, event.component.option("value"), true) }}
                    onFocusOut={event => { form.setFieldTouched(field.name) }}
                    value={field.value}
                    valueChangeEvent="keyup"
                    onKeyUp={event => {
                        form.setFieldValue(field.name, event.component.option("value"), true)
                    }}
                />
            )}
        </Field>
    );
    const DXComboInput = ({ fieldName, fieldType, fieldPlaceholder, ...rest }) => (
        <Field name={fieldName} id={fieldName} type={fieldType} placeholde={fieldPlaceholder} >
            {({ field, form }) => (
                <SelectBox {...rest}
                    name={field.name}
                    placeholder={fieldPlaceholder}
                    mode={fieldType}
                    onValueChanged={event => {
                        form.setFieldValue(field.name, event.component.option("value"), true)
                    }}
                    onFocusOut={event => { form.setFieldTouched(field.name) }}
                    value={field.value}
                />
            )}
        </Field>
    );

    const DXCheckInput = ({ fieldName, fieldType, fieldPlaceholder, ...rest }) => (
        <Field name={fieldName} id={fieldName} type={fieldType} placeholde={fieldPlaceholder} >
            {({ field, form }) => (
                <CheckBox {...rest}
                    name={field.name}
                    placeholder={fieldPlaceholder}
                    mode={fieldType}
                    onValueChanged={event => { form.setFieldValue(field.name, event.component.option("value"), true) }}
                    onFocusOut={event => { form.setFieldTouched(field.name) }}
                    value={field.value}
                />
            )}
        </Field>
    );

    // A custom validation function. This must return an object
    // which keys are symmetrical to our values/initialValues
    const validateCreate = values => {
        const errors = {};

        if (!values.name) {
            errors.name = 'Requerido';
        } else if (values.name.length > 30) {
            errors.name = 'Must be 30 characters or less';
        }

        if (!values.email) {
            errors.email = 'Requerido';
        } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
            errors.email = 'Correo inválido';
        }

        return errors;
    };
    const validateLopd = values => {
        const errors = {};

        if (values.useLopd) {
            if (!values.passwordDuration) {
                errors.passwordDuration = 'Requerido';
            } else if (!(!isNaN(parseFloat(values.passwordDuration)) && isFinite(values.passwordDuration))) {
                errors.passwordDuration = 'Debe ser un número';
            } else if (Number(values.passwordDuration) > 3650) {
                errors.passwordDuration = 'Debe ser 3650 o menos';
            } else if (Number(values.passwordDuration) < 7) {
                errors.passwordDuration = 'Debe ser 7 o más';
            }
            if (!values.passwordRotation) {
                errors.passwordRotation = 'Requerido';
            } else if (!(!isNaN(parseFloat(values.passwordRotation)) && isFinite(values.passwordRotation))) {
                errors.passwordRotation = 'Debe ser un número';
            } else if (Number(values.passwordRotation) > 50) {
                errors.passwordRotation = 'Debe ser 50 o menos';
            } else if (Number(values.passwordRotation) < 0) {
                errors.passwordRotation = 'Debe ser 0 o más';
            }
        }

        return errors;
    };
    const createFormOptions = {
        initialValues: {
            email: '',
            name: '',
            type: 0
        },
        validate: validateCreate,
        onSubmit: async values => {
            setCreateUserState({
                loading: true, email: values.email, name: values.name, type: values.type, error: false, result: null
            });
            //alert(JSON.stringify(values, null, 2));
            const response = await fetch(`${process.env.PUBLIC_URL}/api/users`, {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setCreateUserState({
                loading: false, email: values.email, name: values.name, type: values.type, error: (data.response == null), result: (data.response != null ? data.response : data.message)
            });
            if (data.response != null) {
                hideCreateDialog();
                showMailDialog("Correo al nuevo usuario", values.email)
            }
        }
    };
    const lopdFormOptions = {
        initialValues: {
            useLopd: true,
            passwordDuration: "365",
            passwordRotation: "10"
        },
        validate: validateLopd,
        onSubmit: async values => {
            setLopdState({
                loading: true, useLopd: values.useLopd, passwordDuration: values.passwordDuration, passwordRotation: values.passwordRotation, error: false, result: null
            });
            //alert(JSON.stringify(values, null, 2));
            const response = await fetch(`${process.env.PUBLIC_URL}/api/LOPD`, {
                method: 'POST',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setLopdState({
                loading: false, useLopd: values.useLopd, passwordDuration: values.passwordDuration, passwordRotation: values.passwordRotation, error: (data.response == null), result: (data.response != null ? data.response : data.message)
            });
            if (data.response != null) {
                hideLopdDialog();
            }
        }
    };
    const editFormOptions = {
        initialValues: {
            email: editDialogState.email,
            name: editDialogState.name,
            type: editDialogState.type
        },
        validate: validateCreate,
        onSubmit: async values => {
            setEditUserState({
                id: editDialogState.id, loading: true, email: values.email, name: values.name, type: values.type, result: null
            });
            //alert(JSON.stringify(values, null, 2));
            const response = await fetch(`${process.env.PUBLIC_URL}/api/users`, {
                method: 'PUT',
                body: JSON.stringify({ ...values, id: editDialogState.id }),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setEditUserState({
                id: editDialogState.id, loading: false, email: values.email, name: values.name, type: values.type, result: (data.response != null ? data.response : data.message)
            });
            if (data.response != null) {
                hideEditDialog();
            }
        }
    };
    const deleteUser = async (userId) => {
        const response = await fetch(`${process.env.PUBLIC_URL}/api/users?userId=${userId}`, {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
    }
    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }
    const jsonDataSource = new CustomStore({
        key: 'id',
        loadMode: 'raw', 
        load: () => {
            return fetch(`${process.env.PUBLIC_URL}/api/users`)
                .then(handleErrors)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    setUserCountState(result.response.length);
                    return result.response;
                })
                .catch(() => { throw 'Network error' });
        }
    });

    const onActionCellPrepared = (e) => {
        //if (e.rowType === "data" && e.column.caption === "Acciones") {
        //    if (e.data.userType == 2) {
        //        e.cellElement.querySelectorAll("img").forEach((each) => {
        //            each.style.visibility = "hidden";
        //        });
        //    } else {
        //        e.cellElement.querySelectorAll("img").forEach((each) => {
        //            each.style.visibility = "visible";
        //        });
        //    }
        //}
    }

    return (
        <div className="users-component">
            <div className="container">
                <div className="flex">
                    <BackButton text="Ir atrás" to="" history={history} />
                    <div className="header">
                        <h1>Usuarios&nbsp;</h1>
                        <h4>({userCountState} usuarios)</h4>
                        <div className="space" />
                        
                    </div>
                    <DataGrid
                        dataSource={jsonDataSource}
                        noDataText="Sin usuarios configurados"
                        keyExpr="id"
                        showColumnLines={false}
                        showRowLines={true}
                        showBorders={false}
                        width="100%"
                        height={useWindowSize().height - (156 + 50)}
                        onCellPrepared={onActionCellPrepared}
                    >
                        <DGLoadPanel
                            shadingColor="rgb(92 92 92 / 80%)"
                            enabled={true}
                            showIndicator={true}
                            showPane={true}
                            indicatorSrc={imgSpinner}
                            text="Cargando..."
                        />
                        <Scrolling mode="standard" useNative={true} />
                        <SearchPanel visible={true} placeholder="Buscar..." />
                        <Toolbar>
                            <Item locateInMenu="auto"
                                location="before">
                                <Button
                                    style={{backgroundColor:"white"}}
                                    text="Config. RGPD"
                                    type= "default"
                                    stylingMode= "outlined"
                                    useSubmitBehavior={false}
                                    onClick={event => {
                                            event.element.classList.remove("dx-state-active");
                                            event.element.classList.remove("dx-state-focused");
                                            showLopdDialog("Configuración de RGPD", "otro hola", "mashola");
                                        }
                                    }
                                />
                            </Item>
                            <Item locateInMenu="auto"
                                  location="after">
                                <Button
                                    text="Nuevo usuario"
                                    type="default"
                                    useSubmitBehavior={false}
                                    onClick={event => {
                                            event.element.classList.remove("dx-state-active");
                                            event.element.classList.remove("dx-state-focused");
                                            showCreateDialog("Nuevo usuario", "otro hola", "mashola");
                                        }
                                    }
                                />
                            </Item>
                            <Item locateInMenu="auto"
                                  location="after"
                                  name="searchPanel" />
                        </Toolbar>
                        <Editing
                            mode="row"
                            allowUpdating={true}
                            allowDeleting={true}
                            allowAdding={false} />
                        <Column dataField="name" caption="Nombre" dataType="string" />
                        <Column dataField="mail" caption="Correo Electrónico" dataType="string" />
                        <Column
                            caption="Tipo de usuario"
                            calculateCellValue={(rowData) => {
                                return rowData.userType == 2 ? "Admin" : (rowData.userType == 1 ? "Usuario con permisos" : "Usuario sin permisos");
                            }}
                        />
                        <Column caption="Acciones" type="buttons" dataType="string">
                            {/*<DGButton name="edit" />*/}
                            {/*<DGButton name="delete" />*/}
                            <DGButton
                                inputAttr="dx-button"
                                text="Editar"
                                stylingMode="outlined"
                                type="success"
                                icon={imgEdit}
                                hint="Editar usuario"
                                onClick={(e) => {
                                    showEditDialog("Editar usuario", e.row.data.id, e.row.data.name, e.row.data.mail, e.row.data.userType);
                                }}
                                render= {(data) => {
                                    return <div class="dx-button" style={{ padding: 5, width: 52 }}><img src={imgEdit} class="dx-icon dx-link-icon" title="Editar usuario" /></div>
                                    }
                                }
                            />
                            <DGButton
                                visible={(e) => {
                                    {
                                        return sessionContext.user.id != e.row.data.id
                                    }
                                }}
                                text="Eliminar"
                                stylingMode="outlined"
                                type="success"
                                icon={imgDelete}
                                hint="Eliminar usuario"
                                onClick={(e) => {
                                    showGenericDialog("Eliminar usuario", [`¿Estás seguro de que deseas eliminar el usuario `, <><b>{e.row.data.name}</b>?<br /></>, "Este usuario dejará de tener acceso a DF-Gest de inmediato y todos los trámites asignados a él pasarán a 'No Asignado'."], "Si, eliminar", "No, cancelar", () => { deleteUser(e.row.data.id); hideGenericDialog() }, () => { hideGenericDialog() })
                                }}
                                render={(data) => {
                                    return <div class="dx-button" style={{ padding: 5, width: 52 }}><img src={imgDelete} class="dx-icon dx-link-icon" title="Eliminar usuario" /></div>
                                }}
                            />
                        </Column>
                    </DataGrid>
                </div>
            </div>

            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={createDialogState.popupVisible}
                onHiding={hideCreateDialog}
                
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={createDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <div style={{ display: "flex", "justifyContent": "center" }}> </div>
                <Formik {...createFormOptions}
                    innerRef={createFormRef} >
                    {
                        ({ isSubmitting, handleReset, setFieldTouched, ...props }) => {
                            const hasInputError = (props.touched.password && props.errors.password) || (props.touched.email && props.errors.email);
                            const hasFetchError = (!createUserState.loading && createUserState.email == props.values.email && createUserState.name == props.values.name && createUserState.type == props.values.type && (createUserState.result == null || createUserState.error) );
                            const hasError = (hasInputError || hasFetchError);
                            return <Form className="create-user-form" >
                                <label htmlFor="name">Nombre: *</label>
                                <DXTextInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="name" fieldType="text" className={hasError ? "create-user-input-error" : ""} fieldPlaceholder="Introduce un nombre" />
                                <label style={{ marginTop: "12px" }} htmlFor="email">Correo electrónico: *</label>
                                <DXTextInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="email" fieldType="email" className={hasError ? "create-user-input-error" : ""} fieldPlaceholder="Introduce un correo electrónico" inputAttr={{ autocomplete: 'off' }} />
                                <label style={{ marginTop: "12px" }} htmlFor="type">Tipo de usuario: *<InfoPopover elementId="infoSincronizable" content={
                                    <>
                                        Usuario sin permiso: Solo puede gestionar los trámites que le han sido asignados.<br /><br />
                                        Usuario con permiso: Puede gestionar los trámites que le han sido asignados y puede reasignarlos a otro gestor.<br /><br />
                                        Admin: Igual que el usuario con permiso, pero además puede configurar tipos de trámite y usuarios.
                                    </>
                                } /></label>
                                <DXComboInput disabled={isSubmitting} fieldName="type" fieldType="type" className={hasError ? "create-user-input-error" : ""} fieldPlaceholder="Elige un tipo de usuario" dataSource={[{ name: "Sin permisos", id: 0 }, { name: "Con permisos", id: 1 }, { name: "Admin", id: 2 }]}
                                    valueExpr="id"
                                    displayExpr="name" />
                                
                                {hasError ?
                                    <div className="create-user-error">
                                        {props.touched.email && props.errors.email && props.errors.email == "Requerido" || props.touched.name && props.errors.name && props.errors.name == "Requerido" || props.touched.type && props.errors.type && props.errors.type == "Requerido" ?
                                            "Todos los campos son obligatorios"
                                            :
                                            createUserState.error ? createUserState.result : "Se han proporcionado datos inválidos"
                                        }
                                    </div> :
                                    null
                                }

                                <p>Antes de guardar, revisa que el correo electrónico es el correcto.</p>
                                <p>Tras guardarse, este campo no podrás editarlo y tendrás que eliminar el usuario y volver a crearlo.</p>
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ flex: "auto" }} />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", width: "auto", flexGrow: 0 }}
                                        text="Cancelar"
                                        type="normal"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                                event.element.classList.remove("dx-state-active");
                                                event.element.classList.remove("dx-state-focused");
                                                handleReset();
                                                setFieldTouched("email", false, false);
                                                setFieldTouched("name", false, false);
                                                setFieldTouched("type", false, true);
                                                hideCreateDialog();
                                            }
                                        }
                                    />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                                        text="Guardar"
                                        type="default"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                                event.element.classList.remove("dx-state-active");
                                                event.element.classList.remove("dx-state-focused");

                                            }
                                        }
                                    />
                                </div>
                            </Form>
                        }
                    }
                </Formik>
            </Popup>
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={editDialogState.popupVisible}
                onHiding={hideEditDialog}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={editDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="auto"
                minWidth="650px"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <div style={{ display: "flex", "justifyContent": "center" }}> </div>
                <Formik {...editFormOptions}
                    innerRef={editFormRef} >
                    {
                        ({ isSubmitting, handleReset, setFieldTouched, setFieldValue, ...props }) => {
                            const hasInputError = (props.touched.password && props.errors.password) || (props.touched.email && props.errors.email);
                            const hasFetchError = (!editUserState.loading && editUserState.email == props.values.email && editUserState.name == props.values.name && editUserState.type == props.values.type && (createUserState.result == null || createUserState.error));
                            const hasError = (hasInputError || hasFetchError);
                            return <Form className="create-user-form" >
                                <label htmlFor="name">Nombre: *</label>
                                <DXTextInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="name" fieldType="text" className={hasError ? "create-user-input-error" : ""} fieldPlaceholder="Introduce un nombre" />
                                <label style={{ marginTop: "12px" }} htmlFor="email">Correo electrónico: *</label>
                                <DXTextInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="email" fieldType="email" readOnly={true} disabled={true} className={hasError ? "create-user-input-error" : ""} fieldPlaceholder="Introduce un correo electrónico" inputAttr={{ autocomplete: 'off' }} />
                                <label style={{ marginTop: "12px" }} htmlFor="type">Tipo de usuario: *</label>
                                <DXComboInput disabled={isSubmitting} fieldName="type" fieldType="type" className={hasError ? "create-user-input-error" : ""} fieldPlaceholder="Elige un tipo de usuario" dataSource={[{ name: "Sin permisos", id: 0 }, { name: "Con permisos", id: 1 }, { name: "Admin", id: 2 }]}
                                    valueExpr="id"
                                    displayExpr="name" />

                                {hasError ?
                                    <div className="create-user-error">
                                        {props.touched.email && props.errors.email && props.errors.email == "Requerido" || props.touched.name && props.errors.name && props.errors.name == "Requerido" || props.touched.type && props.errors.type && props.errors.type == "Requerido" ?
                                            "Todos los campos son obligatorios"
                                            :
                                            editUserState.error ? editUserState.result :"Se han proporcionado datos inválidos"
                                        }
                                    </div> :
                                    null
                                }

                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ flex: "auto" }} />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", width: "auto", flexGrow: 0 }}
                                        text="Cancelar"
                                        type="normal"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                            event.element.classList.remove("dx-state-active");
                                            event.element.classList.remove("dx-state-focused");
                                            handleReset();
                                            setFieldTouched("email", false, false);
                                            setFieldTouched("name", false, false);
                                            setFieldTouched("type", false, true);
                                            hideEditDialog();
                                        }
                                        }
                                    />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                                        text="Guardar"
                                        type="default"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                            event.element.classList.remove("dx-state-active");
                                            event.element.classList.remove("dx-state-focused");
                                        }
                                        }
                                    />
                                </div>
                            </Form>
                        }
                    }
                </Formik>
            </Popup>
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={mailDialogState.popupVisible}
                onHiding={hideMailDialog}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={mailDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="600px"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        type: "default",
                        text: 'Cerrar',
                        onClick: () => {
                            hideMailDialog();
                        },
                    }}
                />
                <div style={{ display: "flex", "justifyContent": "center" }}><img src={check_rounded_big} /></div>
                <h4 style={{ fontSize: 17, color: "#002c5d", textAlign: "center", fontFamily: 'roboto-regular' }} >
                    Hemos enviado un correo electrónico al usuario que acabas de crear.
                </h4>
                <h4 style={{ fontSize: 17, color: "#002c5d", textAlign: "center", fontFamily: 'roboto-bold' }}>
                    <b>{mailDialogState.target}</b>
                </h4>
                <h4 style={{ fontSize: 17, color: "#002c5d", textAlign: "center", fontFamily: 'roboto-regular' }} >
                    En él recibirá las instrucciones para acceder a la plataforma.
                </h4>
                <p style={{ textAlign: "center", color: "#002c5d", fontFamily: 'roboto-regular' }} >Si te has equivocado de correo electrónico, elimina el usuario y crea uno nuevo.</p>
            </Popup>
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={genericDialogState.popupVisible}
                onHiding={hideGenericDialog}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={genericDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                {genericDialogState.koText != null ?
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            stylingMode: "outline",
                            text: genericDialogState.koText,
                            onClick: genericDialogState.koCallback,
                        }}
                    />
                    :
                    null
                }
                {genericDialogState.okText != null ?
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            type: "default",
                            text: genericDialogState.okText,
                            onClick: genericDialogState.okCallback,
                        }}
                    />
                    :
                    null
                }
                <p style={{ textAlign: "left", fontFamily: 'roboto-regular' }} >
                    {genericDialogState.body}
                </p>
            </Popup>
            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={lopdDialogState.popupVisible}
                onHiding={hideLopdDialog}

                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={lopdDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="400"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <div style={{ display: "flex", "justifyContent": "center" }}> </div>
                <Formik {...lopdFormOptions}
                    innerRef={lopdFormRef} >
                    {
                        ({ isSubmitting, handleReset, setFieldTouched, ...props }) => {
                            const hasInputError = (props.touched.passwordDuration && props.errors.passwordDuration) || (props.touched.passwordRotation && props.errors.passwordRotation);
                            const hasFetchError = (!lopdState.loading && lopdState.useLopd == props.values.useLopd && lopdState.passwordDuration == props.values.passwordDuration && lopdState.passwordRotation == props.values.passwordRotation && (lopdState.result == null || lopdState.error));
                            const hasError = (hasInputError || hasFetchError);
                            return <Form className="lopd-form" >
                                <div style={{ display: "flex", flexDirection: "row" }}>
                                    <label style={{ width: "100%" }} htmlFor="useLopd">Activar RGPD: </label>
                                    <DXCheckInput style={{ marginBottom: "12px", width: "fit-content" }} disabled={isSubmitting} fieldName="useLopd" fieldType="boolean" className={hasError ? "create-user-input-error" : ""} inputAttr={{ autocomplete: 'off' }} />
                                </div>
                                <label style={{ marginTop: "12px" }} htmlFor="passwordDuration">Duración de la contraseña (días): *</label>
                                <DXTextInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="passwordDuration" fieldType="text" className={hasError ? "create-user-input-error" : ""} fieldPlaceholder="Introduce una duración" />
                                <label style={{ marginTop: "12px" }} htmlFor="passwordRotation">Rotación de contraseñas: *</label>
                                <DXTextInput style={{ marginBottom: "12px" }} disabled={isSubmitting} fieldName="passwordRotation" fieldType="text" className={hasError ? "create-user-input-error" : ""} fieldPlaceholder="Introduce una cantidad" />

                                

                                {hasError ?
                                    <div className="create-user-error">
                                        {props.touched.passwordDuration && props.errors.passwordDuration && props.errors.passwordDuration == "Requerido" || props.touched.passwordRotation && props.errors.passwordRotation && props.errors.passwordRotation == "Requerido" ?
                                            "Los campos son obligatorios si se activa RGPD"
                                            :
                                            lopdState.error ? lopdState.result : "Se han proporcionado datos inválidos"
                                        }
                                    </div> :
                                    null
                                }
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ flex: "auto" }} />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", width: "auto", flexGrow: 0 }}
                                        text="Cancelar"
                                        type="normal"
                                        useSubmitBehavior={false}
                                        onClick={event => {
                                                event.element.classList.remove("dx-state-active");
                                                event.element.classList.remove("dx-state-focused");
                                                handleReset();
                                                setFieldTouched("useLopd", false, false);
                                                setFieldTouched("passwordDuration", false, false);
                                                setFieldTouched("passwordRotation", false, true);
                                                hideLopdDialog();
                                            }
                                        }
                                    />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                                        text="Guardar"
                                        type="default"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                                event.element.classList.remove("dx-state-active");
                                                event.element.classList.remove("dx-state-focused");
                                            }
                                        }
                                    />
                                </div>
                            </Form>
                        }
                    }
                </Formik>
            </Popup>
        </div>
    )
}
