import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router';
import { Link } from 'react-router-dom';
import "./Procedure.css";
import imgGoBack from '../../img/go_back.png';
import { ProcedureStateChooser } from './ProcedureStateChooser';
import { Button, Popup, CheckBox, TextBox, LoadPanel } from 'devextreme-react';
import { ProcedureDataChooser } from './ProcedureDataChooser';
import { Position, ToolbarItem } from 'devextreme-react/popup';
import { ProcedureDocChooser } from './ProcedureDocChooser';
import { BackButton } from '../common/BackButton';
import imgSpinner from '../../img/spinner.gif';

export const Procedure = () => {
    let { id } = useParams();

    let history = useHistory();

    const [loadingState, setLoadingState] = useState(true);
    const [editionState, setEditionState] = useState((id==-1));

    const [nameInputState, setNameInputState] = useState("");
    const [allowMultipleRegistriesChkState, setAllowMultipleRegistriesChkState] = useState(true);
    const [errorState, setErrorState] = useState("");


    const [procedureState, setProcedureState] = useState({
        name: "", dateModif: [
            ('0' + new Date().getDate()).slice(-2),
            ('0' + (new Date().getMonth() + 1)).slice(-2),
            new Date().getFullYear()
        ].join('/')});
    const [stateState, setStateState] = useState([]);
    const [dataState, setDataState] = useState([]);
    const [remoteDataState, setRemoteDataState] = useState([]);
    const [docsState, setDocsState] = useState([]);
    const [remoteDocsState, setRemoteDocsState] = useState([]);
    const [registryCountState, setRegistryCountState] = useState(0);
    const [exportState, setExportState] = useState("");


    const loadProcedure = async () => {

        if (id != -1) {
            return fetch(`${process.env.PUBLIC_URL}/api/procedures/GetProcedure?idProcedure=${id}`)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    console.log(result.response)
                    if (result.response != null) {
                        setProcedureState(result.response);
                        setNameInputState(result.response.name);
                        setAllowMultipleRegistriesChkState(result.response.allowMultipleRegistries);
                    } else {
                        let currentUrl = history.location.pathname;
                        let backlog = [];
                        if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
                            backlog = history.location.state.comesFrom;
                        }
                        backlog.push(currentUrl);
                        history.replace("/procedures", { comesFrom: backlog });
                    }
                })
                .catch(() => { throw 'Network error' });
        } else {
            setProcedureState({
                name: "", dateModif: [
                    ('0' + new Date().getDate()).slice(-2),
                    ('0' + (new Date().getMonth() + 1)).slice(-2),
                    new Date().getFullYear()
                ].join('/')
            });
        }
    };

    const loadStates = async () => {
        return fetch(`${process.env.PUBLIC_URL}/api/procedures/GetStates?idProcedure=${id}`)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(result.response)
                setStateState(result.response)
            })
            .catch(() => { throw 'Network error' });
    };

    const loadRegistryCount = async () => {
        return fetch(`${process.env.PUBLIC_URL}/api/procedures/GetProcedureRegistryCount?idProcedure=${id}`)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(`Registry count ${result.response}`)
                setRegistryCountState(result.response)
            })
            .catch(() => { throw 'Network error' });
    };

    const loadDatas = async () => {
        return fetch(`${process.env.PUBLIC_URL}/api/procedures/GetData?idProcedure=${id}&remote=0`)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(result.response)
                setDataState(result.response)
                return fetch(`${process.env.PUBLIC_URL}/api/procedures/GetData?idProcedure=${id}&remote=1`)
                    .then(response => response.json())
                    .then(result => {
                        // You can process the response here
                        console.log(result.response)
                        setRemoteDataState(result.response)
                    })
                    .catch(() => { throw 'Network error' });
            })
            .catch(() => { throw 'Network error' });
        
    };
    const loadDocs = async () => {
        return fetch(`${process.env.PUBLIC_URL}/api/procedures/GetDocs?idProcedure=${id}&remote=0`)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(result.response)
                setDocsState(result.response)
                return fetch(`${process.env.PUBLIC_URL}/api/procedures/GetDocs?idProcedure=${id}&remote=1`)
                        .then(response => response.json())
                        .then(result => {
                            // You can process the response here
                            console.log(result.response)
                            setRemoteDocsState(result.response)
                        })
                        .catch(() => { throw 'Network error' });
            })
            .catch(() => { throw 'Network error' });
    };
    const loadDocs_DEPR = async () => {
        fetch(`${process.env.PUBLIC_URL}/api/procedures/GetDocs?idProcedure=${id}&remote=0`)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(result.response)
                setDocsState(result.response)
            })
            .catch(() => { throw 'Network error' });
        fetch(`${process.env.PUBLIC_URL}/api/procedures/GetDocs?idProcedure=${id}&remote=1`)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(result.response)
                setRemoteDocsState(result.response)
            })
            .catch(() => { throw 'Network error' });
    };

    const loadAll = async () => {
        setLoadingState(true);
        Promise.all([loadProcedure(), loadStates(), loadDatas(), loadDocs(), loadRegistryCount(), exportProcedure()]).then(() => {
            setLoadingState(false);
        });
    }

    const exportProcedure = async () => {
        setExportState("");
        if (id > 0) {
            return fetch(`${process.env.PUBLIC_URL}/api/procedures/ExportProcedure?idProcedure=${id}`)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    console.log(result.response)
                    if (result.response != null) {
                        setExportState(result.response);
                    } else {

                    }
                })
                .catch(() => { throw 'Network error' });
        }
    }

    const validate = async () => {
        setErrorState("");
        setLoadingState(true);
        return fetch(`${process.env.PUBLIC_URL}/api/procedures/Get`)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                console.log(result.response)
                //Se checkea el nombre
                if (nameInputState.trim() == "") {
                    setLoadingState(false);
                    setErrorState("No se ha proporcionado un nombre para el trámite.");
                    return "No se ha proporcionado un nombre para el trámite."
                }
                if (result.response.find((each) => {
                    return each.id != id && each.name.toLowerCase() == nameInputState.toLowerCase();
                }) != null) {
                    setLoadingState(false);
                    setErrorState("Ya existe un tipo de trámite con el mismo nombre.");
                    return "Ya existe un tipo de trámite con el mismo nombre."
                }
                //Se checkean los estados
                let initState = null;
                initState = stateState.find((each) => {
                    return each.initial && each.shown;
                });
                if (initState == null) {
                    setLoadingState(false);
                    setErrorState("No se ha establecido un estado inicial.");
                    return "No se ha establecido un estado inicial."
                }
                //Todo ok
                setLoadingState(false);
                setErrorState("");
                return "";
            })
            .catch(() => { setLoadingState(false); throw 'Network error' });
    }

    const saveEdit = async (exitAfterSuccess) => {
        validate().then((res) => {
            if (res != "") {
                console.log(res);
            } else {
                if (id > 0) {
                    setLoadingState(true);
                    let values = {
                        id: id,
                        name: nameInputState,
                        allowMultipleRegistries: allowMultipleRegistriesChkState,
                        states: stateState,
                        data: [...dataState, ...remoteDataState],
                        docs: [...docsState, ...remoteDocsState]
                    };
                    console.log(`${process.env.PUBLIC_URL}/api/procedures/Put`);
                    console.log(values);
                    fetch(`${process.env.PUBLIC_URL}/api/procedures/Put`, {
                        method: 'PUT',
                        body: JSON.stringify(values),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then((response) => {
                        response.json().then((data) => {
                            setLoadingState(false);
                            if (data.response == null) {
                                setErrorState(data.message);
                            } else {
                                if (exitAfterSuccess) {
                                    let currentUrl = history.location.pathname;
                                    let backlog = [];
                                    if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
                                        backlog = history.location.state.comesFrom;
                                    }
                                    backlog.push(currentUrl);
                                    history.replace("/procedures", { comesFrom: backlog });
                                } else {
                                    //setEditionState(false);
                                    loadAll();
                                }
                            }
                        });
                    });
                } else {
                    setLoadingState(true);
                    //setCreateUserState({
                    //    loading: true, email: values.email, name: values.name, type: values.type, error: false, result: null
                    //});
                    let values = {
                        id: -1,
                        name: nameInputState,
                        states: stateState,
                        data: [...dataState, ...remoteDataState],
                        docs: [...docsState, ...remoteDocsState]
                    };
                    fetch(`${process.env.PUBLIC_URL}/api/procedures/Post`, {
                        method: 'POST',
                        body: JSON.stringify(values),
                        headers: {
                            'Content-Type': 'application/json'
                        }
                    }).then((response) => {
                        response.json().then((data) => {
                            setLoadingState(false);
                            if (data.response == null) {
                                setErrorState(data.message);
                            } else {
                                if (exitAfterSuccess) {
                                    let currentUrl = history.location.pathname;
                                    let backlog = [];
                                    if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
                                        backlog = history.location.state.comesFrom;
                                    }
                                    backlog.push(currentUrl);
                                    history.replace("/procedures", { comesFrom: backlog });
                                } else {
                                    let currentUrl = history.location.pathname;
                                    let backlog = [];
                                    if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
                                        backlog = history.location.state.comesFrom;
                                    }
                                    backlog.push(currentUrl);
                                    history.replace(`/procedure/${data.response.id}`, { comesFrom: backlog });
                                }
                            }
                        });
                    });
                }



                //setCreateUserState({
                //    loading: false, email: values.email, name: values.name, type: values.type, error: (data.response == null), result: (data.response != null ? data.response : data.message)
                //});
            }
        })
    }


    const [genericDialogState, setGenericDialogState] = useState({
        title: "",
        body: "",
        okText: "",
        koText: "",
        okCallback: () => {

        },
        koCallback: () => {

        },
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showGenericDialog = (title, body, okText, koText, okCallback, koCallback) => {
        setGenericDialogState({
            title: title,
            body: body,
            okText: okText,
            koText: koText,
            okCallback: okCallback,
            koCallback: koCallback,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }
    const hideGenericDialog = () => {
        setGenericDialogState({
            title: "",
            body: "",
            okText: "",
            koText: "",
            okCallback: () => {

            },
            koCallback: () => {

            },
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }



    const [exportDialogState, setExportDialogState] = useState({
        title: "",
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showExportDialog = (title) => {
        setExportDialogState({
            title: title,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
    }
    const hideExportDialog = () => {
        setExportDialogState({
            title: "",
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }

    //useEffect(loadProcedure, [id]);
    //useEffect(loadStates, [id]);
    //useEffect(loadDatas, [id]);
    useEffect(loadAll, [id]);


    return (
        <div className="procedure-component">
            <div className="header">
                <BackButton text="Ir atrás" to="../procedures" history={history} />
                <div className="header">
                    <h1>Configuración del tipo de trámite</h1>
                </div>
                {editionState
                    ?
                    <div style={{ width: "100%", display: "flex", flexDirection: "row", alignItems: "center" }}>
                    <div style={{ width: "100%", }} className="error">{errorState}</div>
                    <div style={{ flex: "auto" }}></div>
                    
                        <Button style={{ marginBottom: "20px", marginLeft: "10px", width: "auto", flexGrow: 0, backgroundColor: "white" }}
                        disabled={loadingState}
                        text="Salir"
                        stylingMode="outlined"
                        useSubmitBehavior={false}
                        onClick={event => {
                                event.element.classList.remove("dx-state-active");
                                event.element.classList.remove("dx-state-focused");
                                showGenericDialog("Salir del tipo de trámite", `¿Estás seguro de que deseas salir? Si no has guardado los cambios, los perderás.`, "Si, salir", "No", () => {
                                    hideGenericDialog();
                                    setEditionState(false);
                                    if (id == -1) {
                                        let currentUrl = history.location.pathname;
                                        let backlog = [];
                                        if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
                                            backlog = history.location.state.comesFrom;
                                        }
                                        backlog.push(currentUrl);
                                        history.replace("/procedures", { comesFrom: backlog });
                                    } else {
                                        loadAll();
                                        let currentUrl = history.location.pathname;
                                        let backlog = [];
                                        if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
                                            backlog = history.location.state.comesFrom;
                                        }
                                        backlog.push(currentUrl);
                                        history.replace("/procedures", { comesFrom: backlog });
                                    }
                                }, () => {
                                    hideGenericDialog();
                                });
                                
                            }
                        }
                    />
                    <Button style={{ marginBottom: "20px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                        disabled={loadingState}
                        text="Guardar"
                        type="default"
                        useSubmitBehavior={false}
                        onClick={async (event) => {
                                event.element.classList.remove("dx-state-active");
                                event.element.classList.remove("dx-state-focused");
                                saveEdit(false);
                            }
                        }
                    />
                    <Button style={{ marginBottom: "20px", marginLeft: "50px", width: "auto", flexGrow: 0 }}
                        disabled={loadingState}
                        text="Guardar y salir"
                        type="default"
                        useSubmitBehavior={false}
                        onClick={event => {
                                event.element.classList.remove("dx-state-active");
                                event.element.classList.remove("dx-state-focused");
                                saveEdit(true)
                            }
                        }
                    />
                    
                    </div>
                    :
                    <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                        <div style={{ flex: "auto" }}></div>
                        <Button style={{ marginBottom: "20px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                            disabled={loadingState}
                            text="Exportar"
                            type="outlined"
                            useSubmitBehavior={false}
                            onClick={event => {
                                    event.element.classList.remove("dx-state-active");
                                    event.element.classList.remove("dx-state-focused");
                                    showExportDialog("Exportar tipo de trámite");
                                }
                            }
                        />
                        <Button style={{ marginBottom: "20px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                            disabled={loadingState}
                            text="Editar"
                            type="default"
                            useSubmitBehavior={false}
                            onClick={event => {
                                    event.element.classList.remove("dx-state-active");
                                    event.element.classList.remove("dx-state-focused");
                                    setEditionState(true);
                                }
                            }
                        />
                    </div>
                }
            </div>
            <div className="body">
                <div className="general">
                    <div className="general_row">
                        <div style={{ whiteSpace: "nowrap", marginRight: 10 }}>Nombre: *</div><TextBox disabled={!editionState || loadingState} onChange={event => { setNameInputState(event.component.option("value")) }} value={nameInputState} /><div /> <div style={{ width: "100%", flex: "auto" }} /> <div style={{ width: "100%", textAlign: "right" }} >Fecha de última modificación: {procedureState.dateModif}</div>
                    </div>
                    <div className="general_row">
                        <div style={{ flex: "auto" }} />
                        <span style={{ whiteSpace: "nowrap", marginRight: 10 }}>Permitir múltiples registros para el mismo expediente: </span>
                        <span><CheckBox value={allowMultipleRegistriesChkState} disabled={!editionState || loadingState} onValueChanged={(e) => { setAllowMultipleRegistriesChkState(e.value) }} /></span>
                    </div>
                </div>
                <div className="states">
                    <h2 >Estados</h2>
                    <ProcedureStateChooser itemsState={stateState} setItemsState={setStateState} disabled={!editionState || loadingState}   />
                </div>
                <div className="data">
                    <h2>Datos</h2>
                    <ProcedureDataChooser headerText="Datos que enviará el concesionario a la gestoría" registryCount={registryCountState} itemsState={remoteDataState} setItemsState={setRemoteDataState} isRemote={true} withMandatory={true} withSync={true} disabled={!editionState || loadingState} />
                    <div style={{ marginBottom: 3, marginTop: 50 }} ></div>
                    <ProcedureDataChooser headerText="Datos que aportará la gestoría al concesionario" registryCount={registryCountState} itemsState={dataState} setItemsState={setDataState} isRemote={false} withMandatory={true} withSync={false} disabled={!editionState || loadingState} />
                </div>
                <div className="docs">
                    <h2>Documentos</h2>
                    <ProcedureDocChooser headerText="Documentos que enviará el concesionario a la gestoría" registryCount={registryCountState}  itemsState={remoteDocsState} setItemsState={setRemoteDocsState} isRemote={true} withMandatory={true} disabled={!editionState || loadingState} />
                    <div style={{ marginTop: 50 }} ></div>
                    <ProcedureDocChooser headerText="Documentos que aportará la gestoría al concesionario" registryCount={registryCountState} itemsState={docsState} setItemsState={setDocsState} isRemote={false} withMandatory={true} disabled={!editionState || loadingState} />
                </div>
            </div>

            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={genericDialogState.popupVisible}
                onHiding={hideGenericDialog}
                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title={genericDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                {genericDialogState.koText != null ?
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            stylingMode: "outlined",
                            text:  genericDialogState.koText ,
                            onClick:  genericDialogState.koCallback ,
                        }}
                    />
                    :
                    null
                }
                {genericDialogState.okText != null ?
                    <ToolbarItem
                        widget="dxButton"
                        toolbar="bottom"
                        location="after"
                        options={{
                            type: "default",
                            text:  genericDialogState.okText,
                            onClick:  genericDialogState.okCallback ,
                        }}
                    />
                    :
                    null
                }
                <p style={{ textAlign: "center", fontFamily: 'roboto-regular' }} >
                    {genericDialogState.body}
                </p>
            </Popup>

            <Popup
                animation={{ hide: null }}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={exportDialogState.popupVisible}
                onHiding={hideExportDialog}
                dragEnabled={false}
                closeOnOutsideClick={true}
                showCloseButton={false}
                showTitle={true}
                title={exportDialogState.title}
                container=".dx-viewport"
                height="auto"
                width="70%"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        type: "default",
                        stylingMode: "outlined",
                        text: 'Copiar',
                        onClick: async () => {
                            let text = document.getElementById('exportCodeTag').innerHTML;
                            try {
                                await navigator.clipboard.writeText(text);
                                console.log('Content copied to clipboard');
                            } catch (err) {
                                console.error('Failed to copy: ', err);
                            }
                        },
                    }}
                />
                <ToolbarItem
                    widget="dxButton"
                    toolbar="bottom"
                    location="after"
                    options={{
                        type: "default",
                        text: 'Cerrar',
                        onClick: () => {
                            hideExportDialog();
                        },
                    }}
                />
                <h4 style={{ textAlign: "center", fontFamily: 'roboto-regular' }} >
                    A continuación se muestra el código de importación del tipo de trámite. Puedes copiarlo y almacenarlo para su posterior importación.
                </h4>
                <pre id="exportCodeTag" style={{ width: "90%", overflowY: "scroll", whiteSpace: "pre-wrap", wordWrap: "break-word", margin: "auto" }} >{ exportState }</pre>
            </Popup>
            <LoadPanel
                shadingColor="rgb(92 92 92 / 80%)"
                visible={loadingState}
                showIndicator={true}
                showPane={true}
                closeOnOutsideClick={false}
                indicatorSrc={imgSpinner}
                message="Cargando..."
            />
        </div>
    )
}
