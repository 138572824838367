import { Popover } from 'devextreme-react'
import React, { useState } from 'react'
import imgInfo from '../../img/info.png';

export const InfoPopover = ({ content, elementId }) => {
    const [openState, setOpenState] = useState(false)
    const toggleOpenState = () => {
        setOpenState(!openState);
    }

    let randomElementId = Math.random().toString(36).substring(7);
    let elId = elementId || randomElementId;
    return (
        <>
            <a
                id={elId}
                onMouseEnter={toggleOpenState}
                onMouseLeave={toggleOpenState}
            ><img style={{marginLeft: 5}} src={imgInfo} /></a>
            <Popover
                  target={"#" + elId}
                  position="right"
                  width={300}
                  visible={openState}
                >
                {content}
            </Popover>
        </>
    )
}
