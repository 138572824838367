import { Button, ColorBox, DataGrid, LoadPanel, Popup, SelectBox, TagBox, TextBox } from 'devextreme-react'
import React, { useCallback, useContext, useEffect, useRef, useState, useMemo } from 'react'
import useWindowSize from '../../hooks/useWindowSizze'
import useUserConfig from '../../hooks/useUserConfig'
import CustomStore from 'devextreme/data/custom_store';
import { Column, Button as DGButton, Editing, SearchPanel, Toolbar, Item, Scrolling, Selection, Paging, Pager, Export, LoadPanel as DGLoadPanel, FilterRow, HeaderFilter, Search } from 'devextreme-react/data-grid';
import "./Registries.css";
import imgDelete from '../../img/delete.png';
import imgEdit from '../../img/edit.png';
import imgGoBack from '../../img/go_back.png';
import check_rounded_big from '../../img/check_rounded_big.png';
import imgSpinner from '../../img/spinner.gif';
import { Link, useHistory, useParams } from 'react-router-dom';
import { Position, ToolbarItem } from 'devextreme-react/popover';
import { Field, Form, Formik } from 'formik';
import { SessionContext } from '../context/SessionContext';
import { Workbook } from 'exceljs';
import saveAs from 'file-saver';
import { exportDataGrid } from 'devextreme/excel_exporter';
import { BackButton } from '../common/BackButton';

export const Registries = () => {

    let { id } = useParams();
    const history = useHistory();

    const windowSize = useWindowSize();
    const userConfig = useUserConfig();


    const { session: sessionContext, setSession: setSessionContext } = useContext(SessionContext);

    const changeStateFormRef = useRef()
    const gridRef = useRef()

    const [changeStateState, setChangeStateState] = useState({ loading: false, ids: null, state: null, error: false, result: null });
    const [registryCountState, setRegistryCountState] = useState(0);
    const [statesState, setStatesState] = useState({ loading: true, results: [] });
    const [byProcedureStatesState, setByProcedureStatesState] = useState({ loading: true, results: [] });
    const [procedureState, setProcedureState] = useState({ loading: true, results: [] });
    const [usersState, setUsersState] = useState({ loading: true, results: [] });
    const [dealerState, setDealerState] = useState({ loading: true, result: null });
    

    const [changeStateButtonState, setChangeStateButtonState] = useState(false);
    const [exportSigaButtonState, setExportSigaButtonState] = useState(false);
    //If the user can assign registries to users the filter should include unassigned, only theirs otherwhise
    const [tagBoxValueState, setTagBoxValueState] = useState([]); //useState(sessionContext.user.userType >= 1 ? [0, sessionContext.user.id] : [sessionContext.user.id]);
    const [procedureTagBoxValueState, setProcedureTagBoxValueState] = useState([]);


    const [gridPaginationState, setGridPaginationState] = useState({});

    const [selectedGridState, setSelectedGridState] = useState([]);

    const onRowSelectionChanged = (e) => {
        e.component.getSelectedRowsData().then((selectedData) => {
            if (selectedData.length == 0) {
                setChangeStateButtonState(false);
                setExportSigaButtonState(false);
            } else {
                if (selectedData.length == 1) {
                    setExportSigaButtonState(true);
                } else {
                    setExportSigaButtonState(false);
                }
                let procedure = 0;
                let allStatesValid = true;
                let allRegistriesAvailable = true;
                selectedData.map((data) => {
                    if (sessionContext.user.userType == 0) {
                        //If the user can't assign registries only those the user has assigned can be changed
                        if (data.assigned != sessionContext.user.id) {
                            allRegistriesAvailable = false;
                        }
                    }
                    if (data.stateId == 6) {
                        allStatesValid = false;
                    }
                    if (procedure != 0) {
                        if (procedure != data.procedureId) {
                            procedure = -1;
                            return;
                        }
                    } else {
                        procedure = data.procedureId;
                    }
                })
                setChangeStateButtonState(procedure > 0 && allStatesValid && allRegistriesAvailable);
            }
            setSelectedGridState(selectedData);
        });
        
    }

    const [changeStateDialogState, setChangeStateDialogState] = useState({
        ids: [],
        procedureId: 0,
        currentState: 0,
        position: { my: 'center', at: 'center', of: window },
        popupVisible: false,
    });
    const showChangeStateDialog = (ids, procedureId, currentState) => {
        setChangeStateDialogState({
            ids: ids,
            procedureId: procedureId,
            currentState: currentState,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: true,
        });
        if (changeStateFormRef != undefined && changeStateFormRef != null && changeStateFormRef.current != undefined && changeStateFormRef.current != null) {
            changeStateFormRef.current.setFieldValue("ids", ids, false);
            if (currentState > 0) {
                changeStateFormRef.current.setFieldValue("state", currentState, false);
            }
        }
    }
    const hideChangeStateDialog = () => {
        setChangeStateDialogState({
            ids: [],
            procedureId: 0,
            currentState: 0,
            position: { my: 'center', at: 'center', of: window },
            popupVisible: false,
        });
    }


    // A custom validation function. This must return an object
    // which keys are symmetrical to our values/initialValues
    const validateChangeState = values => {
        const errors = {};
        if (!values.state) {
            errors.state = 'Requerido';
        } else if (values.state <=0 ) {
            errors.state = 'Requerido';
        }

        return errors;
    };
    const changeStateFormOptions = {
        initialValues: {
            ids: [],
            state: 0
        },
        validate: validateChangeState,
        onSubmit: async values => {
            setChangeStateState({
                loading: true, ids: values.ids, state: values.state, error: false, result: null
            });
            //alert(JSON.stringify(values, null, 2));
            const response = await fetch(`${process.env.PUBLIC_URL}/api/registries/PutState?ids=${JSON.stringify(values.ids)}&state=${values.state}`, {
                method: 'PUT',
                body: JSON.stringify(values),
                headers: {
                    'Content-Type': 'application/json'
                }
            });
            const data = await response.json();
            setChangeStateState({
                loading: false, ids: values.ids, state: values.state, error: (data.response == null), result: (data.response != null ? data.response : data.message)
            });
            if (data.response != null) {
                hideChangeStateDialog();
            }
        }
    };


    const resizeGridPagination = () => {
        try {
            var expGridShowRowCount = Math.floor(((windowSize.height - (156 + 50) - (54 * 2) - 60) / 55));
            setGridPaginationState({
                pageSize: expGridShowRowCount,
                infoText: "Mostrando {2} trámites"
            });
        } catch (ee) {

        }
    }
    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    const jsonDataSource = new CustomStore({
        key: ["id", "procedureId"],
        loadMode: 'raw', 
        load: () => {
            return fetch(`${process.env.PUBLIC_URL}/api/registries/Get?dealer=${id}`)
                .then(handleErrors)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    setRegistryCountState(result.response.length);
                    // De cada uno de los registros, quitamos las horas de las fechas
                    result.response.map((e) => {
                        e.createDate = e.createDate.split(" ")[0];
                        e.changeDate = e.changeDate.split(" ")[0];
                        e.closeDate = e.closeDate.split(" ")[0];
                    });
                    return result.response;
                })
                .catch(() => { throw 'Network error' });
        }
    });
    const loadDealer = async () => {
        fetch(`${process.env.PUBLIC_URL}/api/dealers/GetId?id=${id}`)
            .then(handleErrors)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                setDealerState({ loading: false, result: result.response });
                return result.response;
            })
            .catch(() => { throw 'Network error' });
    }
    const loadStates = async () => {
        fetch(`${process.env.PUBLIC_URL}/api/states`)
            .then(handleErrors)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                setStatesState({ loading: false, results: result.response });
                return result.response;
            })
            .catch(() => { throw 'Network error' });
    }
    const loadByProcedureStates = async () => {
        fetch(`${process.env.PUBLIC_URL}/api/procedures/GetAllProcedureStates`)
            .then(handleErrors)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                setByProcedureStatesState({ loading: false, results: result.response });
                return result.response;
            })
            .catch(() => { throw 'Network error' });
    }
    const loadProcedures = async () => {
        fetch(`${process.env.PUBLIC_URL}/api/procedures/Get`)
            .then(handleErrors)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                setProcedureState({ loading: false, results: result.response });
                return result.response;
            })
            .catch(() => { throw 'Network error' });
    }
    const loadUsers = async () => {
        fetch(`${process.env.PUBLIC_URL}/api/users`)
            .then(handleErrors)
            .then(response => response.json())
            .then(result => {
                // You can process the response here
                setUsersState({ loading: false, results: result.response });
                return result.response;
            })
            .catch(() => { throw 'Network error' });
    }
    const loadAll = async () => {
        Promise.all([loadStates(), loadProcedures(), loadUsers(), loadByProcedureStates(), loadDealer()]).then(() => {
            //setLoadingState(false);
        });
    }


    const DXTextInput = ({ fieldName, fieldType, fieldPlaceholder, ...rest }) => (
        <Field name={fieldName} id={fieldName} type={fieldType} placeholde={fieldPlaceholder} >
            {({ field, form }) => (
                <TextBox {...rest}
                    name={field.name}
                    placeholder={fieldPlaceholder}
                    mode={fieldType}
                    onChange={event => { form.setFieldValue(field.name, event.component.option("value"), true) }}
                    onFocusOut={event => { form.setFieldTouched(field.name) }}
                    value={field.value}
                />
            )}
        </Field>
    );
    const DXComboInput = ({ fieldName, fieldType, fieldPlaceholder, ...rest }) => (
        <Field name={fieldName} id={fieldName} type={fieldType} placeholde={fieldPlaceholder} >
            {({ field, form }) => (
                <SelectBox {...rest}
                    name={field.name}
                    placeholder={fieldPlaceholder}
                    mode={fieldType}
                    onValueChanged={event => {
                        form.setFieldValue(field.name, event.component.option("value"), true)
                    }}
                    onFocusOut={event => { form.setFieldTouched(field.name) }}
                    value={field.value}
                />
            )}
        </Field>
    );

    const exportRegistrySIGA = async () => {
        var idRegistry = selectedGridState.map((e) => e.id)[0];
        if (Number(idRegistry) > 0) {
            fetch(`${process.env.PUBLIC_URL}/api/registries/ExportRegistrySIGA?id=${idRegistry}`)
                .then(response => response.json())
                .then(result => {
                    // You can process the response here
                    console.log(result.response)
                    if (result.response != null) {
                        downloadB64(result.response, idRegistry, ".xml");
                        return true;
                    } else {

                        return false;
                    }
                })
                .catch((er) => {
                    throw 'Network error'
                    return false;
                });
        }
    };

    const downloadB64 = (fileB64, fileName, extension) => {
        const linkSource = `data:application/zip;base64,${fileB64}`;
        const downloadLink = document.createElement("a");
        const fname = fileName + extension;
        downloadLink.href = linkSource;
        downloadLink.download = fname;
        downloadLink.click();
    }

    const onExporting = (e) => {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Trámites');
        e.component.beginUpdate();
        e.component.columnOption('state', 'visible', false);
        e.component.columnOption('exportState', 'visible', true);
        e.component.columnOption('exported', 'visible', false);
        e.component.columnOption('exportExported', 'visible', true);
        e.component.columnOption('downloaded', 'visible', false);
        e.component.columnOption('exportDownloaded', 'visible', true);
        exportDataGrid({
            //component: dGrid.getInstance(document.querySelector(".dx-datagrid")),
            component: e.component,
            worksheet: worksheet,
            customizeCell: function (options) {
                const excelCell = options;
                excelCell.font = { name: 'Arial', size: 12 };
                excelCell.alignment = { horizontal: 'left' };
            }
        }).then(function () {
            workbook.xlsx.writeBuffer()
                .then(function (buffer) {
                    saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'Trámites.xlsx');
                });
            e.component.columnOption('state', 'visible', true);
            e.component.columnOption('exportState', 'visible', false);
            e.component.columnOption('exported', 'visible', true);
            e.component.columnOption('exportExported', 'visible', false);
            e.component.columnOption('downloaded', 'visible', true);
            e.component.columnOption('exportDownloaded', 'visible', false);
            e.component.endUpdate();
        });
        e.cancel = true;
    }

    const onRowClick = (e) => {
        if (e.rowType === "data") {
            //e.component.editRow(e.rowIndex);
            console.log(e);
            let currentUrl = history.location.pathname;
            let backlog = [];
            if (history.location.state != undefined && history.location.state.comesFrom != undefined && history.location.state.comesFrom != "") {
                backlog = history.location.state.comesFrom;
            }
            backlog.push(currentUrl);
            history.replace(`/registry/${id}/${e.key.procedureId}/${e.key.id}`, { comesFrom: backlog });
        }
    }
    //const getFilterValue = useCallback(() => {
    //    let elFiltro = [];
    //    if (tagBoxValueState.length > 0) {
    //        elFiltro.push(['assigned', 'anyof', tagBoxValueState]);
    //    }
    //    if (procedureTagBoxValueState.length > 0) {
    //        if (elFiltro.length > 0) {
    //            elFiltro.push("or")
    //        }
    //        elFiltro.push(['procedureId', 'anyof', procedureTagBoxValueState]);
    //    }
    //    return elFiltro;
    //}, [procedureTagBoxValueState, tagBoxValueState]);
    
    useEffect(loadAll, []);
    useEffect(resizeGridPagination, [windowSize]);
    useEffect(async () => {
        const userFilter=userConfig.get("_USER_FILTER", JSON.stringify(sessionContext.user.userType >= 1 ? [0, sessionContext.user.id] : [sessionContext.user.id]))
        if (tagBoxValueState != JSON.parse(userFilter)) {
            setTagBoxValueState(JSON.parse(userFilter));
        }
        //const procedureFilter = userConfig.get("_PROC_FILTER", JSON.stringify([]))
        //if (procedureTagBoxValueState != JSON.parse(procedureFilter)) {
        //    setProcedureTagBoxValueState(JSON.parse(procedureFilter));
        //}
    },[])
    useEffect(() => {
        if (gridRef != undefined && gridRef != null && gridRef.current != undefined && gridRef.current != null) {

            gridRef.current.instance.filter((itemData) => {
                return (tagBoxValueState.length == 0 ? true : tagBoxValueState.indexOf(itemData.assigned) > -1) && (procedureTagBoxValueState.length == 0 ? true : procedureTagBoxValueState.indexOf(itemData.procedureId) > -1);
            });
        }
    }, [procedureTagBoxValueState, tagBoxValueState])

    const filterCallback = (itemData) => {
        return (tagBoxValueState.length == 0 ? true : tagBoxValueState.indexOf(itemData.assigned) > -1) && (procedureTagBoxValueState.length == 0 ? true : procedureTagBoxValueState.indexOf(itemData.procedureId) > -1);
        //let matchesUser = false;
        //let matchesProcedure = false;
        //if (tagBoxValueState.length == 0) matchesUser = true;
        //if (procedureTagBoxValueState.length == 0) matchesProcedure = true;
        //if (tagBoxValueState.length > 0) {
        //    matchesUser = tagBoxValueState.indexOf(itemData.assigned) > -1;
        //}
        //if (procedureTagBoxValueState.length > 0) {
        //    matchesProcedure = procedureTagBoxValueState.indexOf(itemData.procedureId) > -1;
        //}

        //return matchesUser && matchesProcedure;
    }

    const filterTexts = useMemo(() => {
        return { ok: "OK", cancel: "Cancelar", emptyValue: "(Vacío)" };
    }, []);

    const filterRowOperationDescriptions = useMemo(() => {
        return {
            between: "Entre",
            contains: "Contiene",
            endsWith: "Termina con",
            equal: "Igual",
            greaterThan: "Mayor que",
            greaterThanOrEqual: "Mayor o igual que",
            lessThan: "Menor que",
            lessThanOrEqual: "Menor o igual que",
            notContains: "No contiene",
            notEqual: "No es igual",
            startsWith: "Empieza con"
        }
    }, []);

    return (
        <div className="registries-component">
            <div className="container">

                <div style={{ position: "absolute", bottom: 45, left: 60 }}>{selectedGridState.length > 0 ? `${selectedGridState.length} trámites seleccionados`:"" }</div>
                <div className="flex">
                    <div style={{ display: "flex", flexDirection:"row"}}>
                        <BackButton text="Ir a concesionarios" to="/" history={history} />
                        <div style={{ flex: "auto" }} />
                        <div style={{ display: "flex", flexDirection: "column" }}>
                            <span>{!dealerState.loading ? dealerState.result.name : ""}</span>
                            <span style={{ fontSize: "12px", color: "#7A7A7A" }}>{!dealerState.loading ? dealerState.result.clienteDF : ""}</span>
                        </div>
                    </div>
                    <div className="header">
                        <h1>Trámites&nbsp;</h1>
                        <h4>({registryCountState} trámites)</h4>
                        <div className="space" />
                        
                    </div>
                    {
                        statesState.loading || procedureState.loading || usersState.loading ?
                            <LoadPanel
                                shadingColor="rgb(92 92 92 / 80%)"
                                visible={true}
                                showIndicator={true}
                                showPane={true}
                                closeOnOutsideClick={false}
                                indicatorSrc={imgSpinner}
                                message="Cargando..."
                            />
                            :
                            <DataGrid
                                ref={gridRef}
                                dataSource={jsonDataSource}
                                noDataText="Sin trámites que mostrar"
                                showColumnLines={false}
                                showRowLines={true}
                                showBorders={false}
                                width="100%"
                                filterValue={ [['assigned', 'anyof', tagBoxValueState]] }
                                onSelectionChanged={onRowSelectionChanged}
                                height={windowSize.height - (156 + 50)}
                                onExporting={onExporting}
                                onRowClick={onRowClick}
                                onInitialized={
                                    (e) => {
                                        if (e.component != undefined && e.component != null) {

                                            e.component.filter((itemData) => {
                                                return (tagBoxValueState.length == 0 ? true : tagBoxValueState.indexOf(itemData.assigned) > -1) && (procedureTagBoxValueState.length == 0 ? true : procedureTagBoxValueState.indexOf(itemData.procedureId) > -1);
                                            });
                                        }
                                    }
                                }
                            >
                                <DGLoadPanel
                                    shadingColor="rgb(92 92 92 / 80%)"
                                    enabled={true}
                                    showIndicator={true}
                                    showPane={true}
                                    indicatorSrc={imgSpinner}
                                    text="Cargando..."
                                />
                                <FilterRow visible={true}
                                    applyFilterText="Aplicar"
                                    betweenEndText="Hasta"
                                    betweenStartText="Desde"
                                    resetOperationText="Reiniciar"
                                    showAllText="Mostrar todo"
                                    operationDescriptions={filterRowOperationDescriptions}
                                />
                                <HeaderFilter visible={true}
                                    texts={filterTexts} />
                                <Export enabled={true} allowExportSelectedData={true} texts={{ exportAll: "Exportar todos", exportSelectedRows: "Exportar seleccionados"}} />
                                <Scrolling mode="standard" useNative={true} />
                                <SearchPanel visible={true} placeholder="Buscar..." />
                                <Toolbar>
                                    <Item locateInMenu="auto"
                                        location="before"
                                        name="changeStateButton"
                                        cssClass="change-state-button">
                                        <Button
                                            text="Cambiar estado"
                                            type="normal"
                                            useSubmitBehavior={false}
                                            disabled={!changeStateButtonState || byProcedureStatesState.loading}
                                            onClick={event => {
                                                    event.element.classList.remove("dx-registry-active");
                                                    event.element.classList.remove("dx-registry-focused");
                                                    showChangeStateDialog(selectedGridState.map((e) => e.id), selectedGridState[0].procedureId, 0);
                                                }
                                            }
                                        />
                                    </Item>
                                    <Item
                                        locateInMenu="auto"
                                        location="before"
                                        name="exportButton"
                                        cssClass="export-button"
                                    />
                                    {/*<Item locateInMenu="auto"*/}
                                    {/*    location="before"*/}
                                    {/*    name="sigaButton"*/}
                                    {/*    cssClass="siga-button" >*/}
                                    {/*    <Button*/}
                                    {/*        text="Exportar a SIGA"*/}
                                    {/*        type="normal"*/}
                                    {/*        disabled={!exportSigaButtonState}*/}
                                    {/*        useSubmitBehavior={false}*/}
                                    {/*        onClick={event => {*/}
                                    {/*                event.element.classList.remove("dx-registry-active");*/}
                                    {/*                event.element.classList.remove("dx-registry-focused");*/}
                                    {/*                exportRegistrySIGA();*/}
                                    {/*            }*/}
                                    {/*        }*/}
                                    {/*    />*/}
                                    {/*</Item>*/}
                                    <Item locateInMenu="auto"
                                        location="after">
                                        <TagBox dataSource={procedureState.results}
                                            placeholder="Filtrar tipo de trámite"
                                            valueExpr="id"
                                            displayExpr="name"
                                            searchEnabled={true}
                                            showSelectionControls={true}
                                            showDropDownButton={true}
                                            applyValueMode="instantly"
                                            value={procedureTagBoxValueState}
                                            selectAllText="Todos"
                                            onSelectionChanged={(e) => {
                                                if (e.component.option("value") != procedureTagBoxValueState) {
                                                    if (e.component.option("value").length > 0) {
                                                        //userConfig.set("_PROC_FILTER", JSON.stringify(e.component.option("value")));
                                                        setProcedureTagBoxValueState(e.component.option("value"));
                                                    } else {
                                                        //userConfig.set("_PROC_FILTER", JSON.stringify([]));
                                                        setProcedureTagBoxValueState([]);
                                                    }
                                                }
                                            }}
                                        />
                                    </Item>
                                    <Item locateInMenu="auto"
                                        location="after">
                                        <TagBox dataSource={[{ id: 0, name: "Sin asignar" }, ...usersState.results]}
                                            placeholder="Filtrar usuarios"
                                            valueExpr="id"
                                            displayExpr="name"
                                            searchEnabled={true}
                                            showSelectionControls={true}
                                            showDropDownButton={true}
                                            applyValueMode="instantly"
                                            value={tagBoxValueState}
                                            selectAllText="Todos"
                                            onSelectionChanged={(e) => {
                                                if (e.component.option("value") != tagBoxValueState) {
                                                    if (e.component.option("value").length > 0) {
                                                        userConfig.set("_USER_FILTER", JSON.stringify(e.component.option("value")));
                                                        setTagBoxValueState(e.component.option("value"));
                                                    } else {
                                                        userConfig.set("_USER_FILTER", JSON.stringify([]));
                                                        setTagBoxValueState([]);
                                                    }
                                                }
                                            }}
                                        />
                                    </Item>
                                    
                                    <Item locateInMenu="auto"
                                        location="after"
                                        name="searchPanel" />
                                </Toolbar>
                                <Selection mode="multiple" deferred={true} showCheckBoxesMode="always" />
                                <Scrolling rowRenderingMode='virtual'></Scrolling>
                                <Paging defaultPageSize={10} pageSize={gridPaginationState.pageSize} />
                                <Pager
                                    infoText={gridPaginationState.infoText }
                                    visible={true}
                                    showPageSizeSelector={false}
                                    showInfo={true}
                                    showNavigationButtons={true} />
                                {/*<Editing*/}
                                {/*    mode="row"*/}
                                {/*    allowUpdating={true}*/}
                                {/*    allowDeleting={true}*/}
                                {/*    allowAdding={false} />*/}
                                <Column name="state" dataField="stateId" caption="Estado" dataType="string"
                                    cellRender={(data) => {
                                            let stateName = "Cargando...";
                                            let stateBGColor = "#FFFFFF";
                                            const state = statesState.results.find((e) => {
                                                return e.id == data.data.stateId;
                                            });
                                            if (state != undefined && state != null) {
                                                stateName = state.name;
                                                stateBGColor = state.bgColor;
                                            }
                                            //{ { backgroundColor: data.value, width: 50, height: 20, borderColor: "#000000", borderStyle: "solid", borderWidth: 0.2, borderRadius: 5 }}
                                            return <div style={{ display: "flex", flexDirection: "row", flexWrap: "nowrap", cursor: "pointer" }}><div style={{ backgroundColor: stateBGColor, width: 10, minWidth:10, height: 40, borderBottomLeftRadius: 12, borderTopLeftRadius: 12, marginRight: 10, boxShadow: (state.id == 1 ? "0 0 0 1px var(--azul-df)":"unset") }}></div>{stateName}</div>
                                        }
                                    }
                                />
                                {/*This is the column that gets exported*/}
                                <Column name="exportState" dataField="stateId" caption="Estado" dataType="string" visible={false}
                                    calculateCellValue={(rowData) => {
                                        const state = statesState.results.find((e) => {
                                            return e.id == rowData.stateId;
                                        });
                                        if (state != undefined && state != null) {
                                            return state.name;
                                        } else {
                                            return rowData.stateId;
                                        }
                                    }}
                                />
                                <Column dataField="plate" caption="Matrícula" dataType="string" />
                                <Column dataField="chassis" caption="Bastidor" dataType="string" />
                                <Column dataField="id" caption="ID Trámite" dataType="string"
                                            calculateCellValue={(rowData) => {
                                                return `${String(rowData.gestoria).padStart(3, "0")}${String(rowData.procedureId).padStart(4, "0")}${String(rowData.id).padStart(6, "0")}`;
                                            }
                                        }
                                />
                                <Column dataField="procedureId" caption="Tipo" dataType="string"
                                    calculateCellValue={(rowData) => {
                                        const procedure = procedureState.results.find((e) => {
                                            return e.id == rowData.procedureId;
                                        });
                                        if (procedure != undefined && procedure != null) {
                                            return procedure.name;
                                        } else {
                                            return rowData.procedureId;
                                        }
                                    }}
                                />
                                <Column dataField="createDate" caption="Fecha de alta" dataType="date" format="dd/MM/yyyy" />
                                <Column dataField="changeDate" caption="Fecha de modificación" dataType="date" format="dd/MM/yyyy" />
                                <Column dataField="closeDate" caption="Fecha de cierre" dataType="date" format="dd/MM/yyyy"
                                    cellRender={(data) => {
                                            let stateBGColor = "var(--azul-df)";
                                            if (data.data.aboutToGetDeleted) {
                                                stateBGColor = "var(--rojo-ko)";
                                            }
                                            //{ { backgroundColor: data.value, width: 50, height: 20, borderColor: "#000000", borderStyle: "solid", borderWidth: 0.2, borderRadius: 5 }}
                                            return <div style={{ color: stateBGColor }}>{data.data.closeDate}</div>
                                        }
                                    }
                                />
                                {/*<Column name="exported" dataField="exported" caption="Exportado" dataType="boolean" />*/}
                                {/*<Column name="exportExported" dataField="exported" caption="Exportado a SIGA" dataType="string" visible={true}*/}
                                {/*    calculateCellValue={(rowData) => rowData.exported ? "Si" : "No"}*/}
                                {/*/>*/}
                                <Column dataField="assigned" caption="Asignado a" dataType="string"
                                    customizeText={(cellInfo) => {
                                        if (cellInfo.value <= 0) {
                                            return "-";
                                        }
                                        const user = usersState.results.find((e) => {
                                            return e.id == cellInfo.value;
                                        });
                                        if (user != undefined && user != null) {
                                            return user.name;
                                        } else {
                                            return `- Usuario eliminado (${String(cellInfo.value)}) -`;
                                        }
                                    }}
                                />
                                {/*<Column dataField="downloaded" caption="Descargado" dataType="boolean" />*/}
                                <Column name="exportDownloaded" dataField="downloaded" caption="Descargado" dataType="string" visible={true}
                                    calculateCellValue={(rowData) => rowData.downloaded ? "Si" : "No"}
                                />
                            </DataGrid>
                    }
                </div>
            </div>

            <Popup
                animation={{hide: null}}
                onShown={(e) => {
                    e.component.repaint();
                }}
                visible={changeStateDialogState.popupVisible}
                onHiding={hideChangeStateDialog}

                dragEnabled={false}
                closeOnOutsideClick={false}
                showCloseButton={false}
                showTitle={true}
                title="Cambiar estado de los trámites seleccionados"
                container=".dx-viewport"
                height="auto"
                width="auto"
            >
                <Position
                    at="center"
                    my="center"
                    of="window"
                />
                <div style={{ display: "flex", "justifyContent": "center" }}> </div>
                <Formik {...changeStateFormOptions} innerRef={changeStateFormRef} >
                    {
                        ({ isSubmitting, handleReset, setFieldTouched, ...props }) => {
                            const hasInputError = (props.touched.state && props.errors.state);
                            const hasFetchError = (!changeStateState.loading && changeStateState.state == props.values.state && (changeStateState.result == null || changeStateState.error));
                            const hasError = (hasInputError || hasFetchError);

                            const statesDataSource = (byProcedureStatesState.results[changeStateDialogState.procedureId] || []).filter((e) => e.shown && e.state!=null && e.state.id!=1).sort((a, b) => parseFloat(a.order) - parseFloat(b.order));

                            return <Form className="change-state-form" >
                                <p>Al pulsar el botón guardar, los trámites seleccionados cambiaran al estado que selecciones.</p>
                                <p>Los estados de 'Incidencia' y 'Finalizado' no pueden ser establecidos desde aquí porque requieren comprobaciones adicionales.</p>
                                <label style={{ marginTop: "12px" }} htmlFor="state">Nuevo estado: *</label>
                                {/*States 4 ("incidencia") and 6 ("finalizado") aren't available because to set them there are some checks required that can't be done from here.*/}
                                <DXComboInput disabled={isSubmitting} fieldName="state" fieldType="state" className={hasError ? "change-state-input-error" : ""} fieldPlaceholder="Elige un estado" dataSource={statesDataSource.filter((e) => e.state.id != 4 && e.state.id!=6)}
                                    valueExpr="state.id"
                                    displayExpr="state.name" />

                                {hasError ?
                                    <div className="change-state-error">
                                        {props.touched.state && props.errors.state && props.errors.state == "Requerido"  ?
                                            "Todos los campos son obligatorios"
                                            :
                                            changeStateState.error ? changeStateState.result : "Se han proporcionado datos inválidos"
                                        }
                                    </div> :
                                    null
                                }
                                <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                    <div style={{ flex: "auto" }} />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", width: "auto", flexGrow: 0 }}
                                        text="Cancelar"
                                        type="normal"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                            event.element.classList.remove("dx-state-active");
                                            event.element.classList.remove("dx-state-focused");
                                            handleReset();
                                            setFieldTouched("state", false, false);
                                            hideChangeStateDialog();
                                        }
                                        }
                                    />
                                    <Button disabled={isSubmitting} style={{ marginTop: "33px", marginLeft: "10px", width: "auto", flexGrow: 0 }}
                                        text="Guardar"
                                        type="default"
                                        useSubmitBehavior={true}
                                        onClick={event => {
                                            event.element.classList.remove("dx-state-active");
                                            event.element.classList.remove("dx-state-focused");

                                        }
                                        }
                                    />
                                </div>
                            </Form>
                        }
                    }
                </Formik>
            </Popup>
        </div>
    )
}
